import React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import Layout from "../components/layout"
import BusinessCard from "../components/businessCard"
import { BackgroundScrollButton } from "../components/buttons"

import "./blog.css"

export default function About({ data }) {
  const bannerHeader = data.bannerHeader.childImageSharp.fluid
  const bannerShadow = data.bannerShadow.childImageSharp.fluid
  const content = data.allFile.edges
  return (
    <Layout>
      <div className='background-image'>
        <Image fluid={bannerHeader} className='background-image-medium banner-header-background' />
        <h1 className='banner-header-text'>BLOG</h1>
        <Image
          fluid={bannerShadow}
          className='background-rounded-shadow'
          style={{ position: "absolute" }}
          loading="eager"
        />
        <BackgroundScrollButton elementId='content'/>
      </div>
      <div className='content-container'>
        {content.map((c, index) => {
          const article = c.node.childBlogJson
          return (
            <div key={index} className='paragraph'>
              <div>
                <h2 style={{textAlign: 'right', margin: 0}}>({new Date(article.creation_date * 1000).toLocaleString()})</h2>
                <h1>{article.title}</h1>
              </div>
              <BusinessCard
                image={article.main_image.childImageSharp.fluid}
                shadow={bannerShadow}
                title={article.title}
                shortDetails={article.veryShortDescription}
                details={article.shortDescription}
                button={{
                  text: 'Czytaj',
                  url: `/blog/${article.slug}`
                }}
              />
            </div>
          )
        })}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allFile(filter: {relativeDirectory: {eq: "blog"}}, sort: {order: DESC, fields: name}) {
      edges {
        node {
          childBlogJson {
            slug
            title
            creation_date
            veryShortDescription
            shortDescription
            main_image {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    bannerHeader: file(relativePath: {eq: "blog/images/banner_header.JPG"}) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bannerShadow: file(relativePath: {eq: "images/banner_header_shadow.png"}) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
